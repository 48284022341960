.root {
	composes: g-grid from global;
	composes: g-grid-container from global;
	row-gap: var(--wpl-spacing-08);
}

.intro {
	grid-column: 1 / -1;

	@media (--medium-up) {
		grid-column: 1 / 9;
	}
}

.textBlocks {
	--columns: 1;
      
	grid-column: 1 / -1;
	composes: g-grid from global;
	grid-template-columns: repeat(var(--columns), 1fr);
	grid-gap: var(--wpl-spacing-08) var(--wpl-spacing-06);
      
	@media (--medium) {
		--columns: 2;
	}
      
	@media (--large) {
	  	--columns: var(--maxColumns);
	}
}

.textBlock {
	display: flex;
	flex-direction: column;
}

.icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: var(--wpl-spacing-08);
	height: var(--wpl-spacing-08);
	margin-bottom: var(--wpl-spacing-03);
	border-radius: 4px;
	background-color: var(--brand);
	color: var(--brand-text-on-primary);
}

.heading {
	padding-bottom: var(--wpl-spacing-01);
}